import "./import/modules";
import "./import/components";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

if(ScrollTrigger.isTouch !== 1) {
    ScrollSmoother.create({
        wrapper: '.wrapper',
        content: '.content',
        smooth: 1.3,
        effects: true,
    });


    let leafs = gsap.utils.toArray('.leaf img');

    leafs.forEach(item => {
        function getRandomInt(min, max) {
            min = -270;
            max = 270;
            return Math.floor(Math.random() * (max - min)) + min;
        }
        
        gsap.to(item, {
            rotate: getRandomInt,
            scale: 0.9,
            scrollTrigger: {
                trigger: item,
                start: '-1000',
                scrub: true,
            }
        })
    });

    gsap.from(
        '.about__img-wrap', 
        {x:50, opacity: 0,
        scrollTrigger: {
            trigger: '.about',
            start: '-1600',
            scrub: true,
        }
    })


    let scroll_tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.products__content',
            start: "top center",
            scrub: true,
            end: "+=300",
        }
    }),

    products = [...document.querySelectorAll('.products__item')]

    scroll_tl.to(products, {
        xPercent: -95 * (products.length - 1),
        scrollTrigger: {
            trigger: ".products",
            start: "center center",
            pin: true,
            scrub: 1,
            snap: 1 / (products.length - 1),
            end: () => `+=4320`
        }
    });
}

function getSamePageAnchor(link) {
    if (
      link.protocol !== window.location.protocol ||
      link.host !== window.location.host ||
      link.pathname !== window.location.pathname ||
      link.search !== window.location.search
    ) {
      return false;
    }
  
    return link.hash;
  }
  // Scroll to a given hash, preventing the event given if there is one
  function scrollToHash(hash, e) {
    const elem = hash ? document.querySelector(hash) : false;
    if (elem) {
      if (e) e.preventDefault();
      gsap.to(window, { autoKill:false, duration: 1.2, ease: "power1.inOut", scrollTo: elem });
    }
  }
  // If a link's href is within the current page, scroll to it instead
  document.querySelectorAll("a[href]").forEach((a) => {
    a.addEventListener("click", (e) => {
      scrollToHash(getSamePageAnchor(a), e);
    });
  });
  // Scroll to the element in the URL's hash on load
scrollToHash(window.location.hash);