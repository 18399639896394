import "%modules%/header/header";
import "%modules%/hero/hero";
import "%modules%/about/about";
import "%modules%/map/map";
import "%modules%/mission/mission";
import "%modules%/distribution/distribution";
import "%modules%/products/products";
import "%modules%/footer/footer";

import "%modules%/burger/burger";
import "%modules%/offcanvas/offcanvas";
import "%modules%/modal/modal";
import "%modules%/form/form";