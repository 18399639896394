$(function(){
    $('.callback').click(function() {
        $('.modal').toggleClass('modal--active');
    })

    $('.btn-close').click(function() {
        $('.modal').removeClass('modal--active');
        $('.form__message').removeClass('form__message--show');
        $('.form__message--success').removeClass('form__message--success-show');
    });
});